* {
  padding: 0;
  margin: 0;
}

html,
body,
#root {
  position: relative;
  width: 100%;
  min-height: 99% !important;
  height: 99%;
}

body{
  background-color:#1C1C1C ;

}
.App {
  position: relative;
  /* border: 1px solid rgb(255, 99, 99); */
  /* width:100%; */
  height: 100%;
}







.task-list-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* border: 1px solid red; */
  background-color:#3B0B39;
 
  width: 100%;

  -webkit-box-shadow: 0px -1px 31px 2px rgba(0,0,0,0.75);
-moz-box-shadow: 0px -1px 31px 2px rgba(0,0,0,0.75);
box-shadow: 0px -1px 31px 2px rgba(0,0,0,0.75);

}

#arrow-down{
  margin-left: 5px;
  padding-top: 5px;
  /* border: 1px solid blue; */
}

.task-list-header-title-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 3em;
  align-items: center;
  color: white;
  /* border: 1px solid blue; */
  width: 30%;

}

#task-list-header-title {
  font-weight: 700;
  color: white;

}


.task-list-popup {
  width: 20em;
  min-height: 10%;
  max-height: 40%;
  margin-top: 10%;
  /* border: 1px solid green; */
  background-color: white;
  position: relative;
  border-radius: 5px;
}

#task-list-item {
  position: relative;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  padding: 5px;
  height: 3em;
  border-bottom: 1px solid rgb(73, 73, 73);
  /* width: 100%; */

}

#backdrop {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
  height: 95%;
  /* border: 2px solid red; */
  background: #726e7486;
  z-index: 5;
}

.task-list-popup-header {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgb(168, 168, 168);
  height: 3em;

}

.task-list-popup-content {
  position: relative;
  /* border: 2px solid rgb(6, 255, 39); */
  width: 100%;
  height: 80%;

  overflow-y: auto;
  overflow-x: hidden;
}

.task-list-popup-header-title {
  /* border: 2px solid rgb(6, 255, 39); */
  justify-content: center;
  text-align: center;
  line-height: 2.5em;
  font-size: large;
  font-weight: 600;
  flex-grow: 1;
}




/* Task List view */
.TaskListView {
  position: relative;
  /* display: flex; */
  /* border: 2px solid rgb(221, 148, 11); */
  height: 88%;
  padding-top: 4em;
  overflow-y: auto;
}

#task-container {
  margin-left: 20%;
}

#task-item {
  /* position: relative; */
  display: flex;
  align-items: center;
  /* border: 2px solid rgb(6, 255, 39); */
  background-color: #9c416f;
  width: 80%;
  height: 5em;
  margin: 10px;
  border-radius: 5px;
  padding: 5px;
  color: white;
}

.auth-popup-content {
  position: relative;
  /* border: 2px solid rgb(6, 255, 39); */
  width: 100%;
  height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
}

#signout_button {
  position: absolute;
  border: none;
  border-radius: 5px;
  color: white;
  background-color: rgba(120, 143, 125, 0.678);
  width: 6em;
  height: 2em;
  top: 1%;
  right: 1%;
  z-index: 5;
}

#authorize_button {
  position: relative;
  margin-top: 30%;
  margin-left: 30%;
  width: 10em;
  height: 3em;
  padding: 3px;

  border: none;
  border-radius: 5px;
  color: white;
  background-color: #B40486;
}

#task-list-item-title {
  /* border: 1px solid rgb(200, 255, 0); */
  margin-left: 5px;
  /* flex-grow: 1; */
  /* width: 50%; */
  white-space: nowrap;
  overflow: hidden;
  height: 1.5em;
  font-weight: 450;
  text-overflow: ellipsis;
}

#task-item-title {
  flex-grow: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#task-item-check{
  /* border: 1px solid red; */
}

.completed-tracker{
  position: absolute;
  justify-content: center;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  width:25%;
  /* height: 2em; */
  padding: 3px;
  left: 37%;
  top: 7%;
  z-index: 10;

  border-radius: 20px;
  background-color: #9162a0;
  color: white;

  -webkit-box-shadow: 0px 0px 98px -3px rgba(54,54,54,1);
  -moz-box-shadow: 0px 0px 98px -3px rgba(54,54,54,1);
  box-shadow: 0px 0px 98px -3px rgba(54,54,54,1);
  
}

#show-tasks{
  border: 2px solid white;
  border-radius: 5px;
  margin-left: 5px;
  padding: 5px;
  color: white;
  background-color: #9162a0;
}